export default {
   'common.login':'Login',
   'common.please_input_your_name' :"Please input your name",
   'common.please_input_your_pwd': 'Please input your password',
   'common.please_repeat_your_pwd' :'Please repeat your password',
   'common.password_mismatch':'The repeated password mismatch with the input password',
   'common.account_login':'Sign In',
   'common.regist_account' :'Sign Up',
   'common.regist' :'Sign Up',
   'common.password_no_less_than_5_digits' :'Password should be no less than five digits',
   'common.please_input_validation_code':'Please input validation code',
   'common.please_input_the_correct_email_addr':'Please input the correct email address',
   'common.created_at':'Created At',
   'common.password':'Password',
}